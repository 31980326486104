import FileSaver from 'file-saver';

export default {
  data: () => ({
    pickedItems: [],
    selectedItem: null,
    idsToDelete: [],
    tblLoading: false,
    dialog: {
      create: false,
      detail: false,
      update: false,
      addFee: false,
      delete: false,
      import: false
    },
    querySilent: true,
    tblConfig: {
      footerProps: {
        showFirstLastPage: true,
        'items-per-page-options': [15, 25, 50, 100, -1]
      },
      showSelect: true,
      text: {
        loading: 'Loading ...',
        noItem: 'No item found'
      },
    },
    tblOptions: {}
  }),
  computed: {
    listItems() {
      return this.items
    },
    getIndexOfItem() {
      return item => {
        let pageOffset = this.tblPagination ? (this.tblPagination.current_page - 1) * this.tblPagination.per_page : 0
        return pageOffset + this.items.indexOf(item) + 1
      }
    },
    queryConf() {
      let conf = {
        options: this.tblOptions,
        searchWith: this.searchWith,
        searchWithCount: this.searchWithCount,
        querySilent: this.querySilent
      }
      let sortBy = conf.options.sortBy;
      let sortDesc = conf.options.sortDesc;
      if (!sortBy) sortBy = [];
      if (!sortDesc) sortDesc = [];
      if (sortBy.indexOf('created_at') < 0 && sortBy.length === 0) {
        sortBy.unshift('created_at')
        sortDesc.unshift(true)
      } else if(sortBy.length > 1) {
        sortBy.splice(sortBy.indexOf('created_at'), 1);
        sortDesc.splice(0, 1);
      }
      conf.options.sortBy = sortBy;
      conf.options.sortDesc = sortDesc;
      return conf
    }
  },
  methods: {
    onClickRow($event, row) {
      console.log('$event', $event);
      console.log('row', row);
    },

    onClickNew() {
      this.dialog.create = true;
    },

    onClickImport() {
      this.dialog.import = true;
    },

    onClickExport() {
      this.exportListItems({
        ...this.queryConf,
        searchFields: this.searchFields,
      }).then(res => {
        let fileName = res.headers && res.headers['content-disposition'] ? res.headers['content-disposition'].replace('attachment; filename=', '') : null;
        if (fileName) FileSaver.saveAs(res.data, fileName);
        else FileSaver.saveAs(res.data);
      }).catch(err => {
        console.log(err.message)
      })
    },
    /**
     * open confirm box for delete 1 item
     * @param props
     */
    onClickDelete(props) {
      this.dialog.delete = true;
      this.idsToDelete = [props.item.id];
    },

    onClickEdit(props) {
      this.selectedItem = props.item;
      this.dialog.update = true;
    },

    onClickDetail(props) {
      this.selectedItem = props.item;
      this.dialog.detail = true;
    },
    onClickAddFee(props) {
      this.selectedItem = props.item;
      this.dialog.addFee = true;
    },

    /**
     * open confirm box for delete multi item
     */
    onClickDeleteAll() {
      this.dialog.delete = true;
      this.idsToDelete = this.pickedItems.map(i => i.id);
    },
    /**
     * callback after created
     */
    onCreated() {
      this.dialog.create = false;
      this.doSearch();
    },
    /**
     * callback after updated
     */
    onUpdated() {
      this.dialog.update = false;
      this.doSearch();
    },
    /**
     * callback after delete items
     */
    onDeleted() {
      this.dialog.delete = false;
      this.idsToDelete = [];
      this.doSearch();
    },
    /**
     * callback after import items
     */
    onImported() {
      this.dialog.import = false;
      this.doSearch();
    },

    doSearch() {
      this.pickedItems = [];
      this.selectedItem = null;
      this.tblLoading = true;
      if (this.searchFields.created_date_from.value && this.searchFields.created_date_to.value) {
        const fromData = this.searchFields.created_date_from.value;
        const toData = this.searchFields.created_date_to.value;
        if (new Date(this.searchFields.created_date_from.value) > new Date(this.searchFields.created_date_to.value)) {
          this.searchFields.created_date_from.value = toData;
          this.searchFields.created_date_to.value = fromData;
        }
      }
      this.getListItems({
        ...this.queryConf,
        searchFields: this.searchFields,
      }).then(res => {
        if (this.tblOptions.page > res.data.last_page) this.tblOptions.page = res.data.last_page;
      }).finally(() => {
        this.tblLoading = false
      });
    },
  },
  watch: {
    'queryConf': {
      handler() {
        this.doSearch();
      },
      deep: true
    },
  }
}
