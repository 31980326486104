<template>
  <v-card class="mx-auto pa-5">
    <v-card-title class="mx-0 px-0">Tạo Kiện Lớn</v-card-title>
    <v-row>
      <v-col cols="6">
        <label>Tên Kiện Lớn</label>
        <v-text-field dense outlined label="Name" v-model="item.name"/>
      </v-col>
      <v-col cols="6">
        <label>Mã Kiện Lớn</label>
        <v-text-field dense outlined label="Code" v-model="item.code"/>
      </v-col>
      <v-col cols="6">
        <label>Ghi Chú</label>
        <v-text-field dense outlined label="Note" v-model="item.note"/>
      </v-col>
      <v-col cols="6">
        <label>Kho</label>
        <v-select v-model="item.warehouse_name" :items="warehouse_option" class="select-status" dense item-text="name" item-value="value"
                  outlined></v-select>
      </v-col>
      <v-col cols="6">
        <label>Trọng Lượng</label>
        <v-text-field type="number" dense outlined label="Weight" v-model="item.weight"/>
      </v-col>
      <v-col cols="12">
        <label>Danh Sách Orders</label>
        <v-textarea placeholder="List Order Code or Tracking Number, each code in 1 line" dense outlined v-model="item.orderCodeOrTrackingNumbers"></v-textarea>
      </v-col>
    </v-row>
    <v-card-actions class="mx-0 pa-0">
      <v-spacer></v-spacer>
      <v-btn color="secondary" text @click="onCancel">Cancel</v-btn>
      <v-btn color="primary" text @click="processAdd">Create</v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import {mapActions, mapGetters} from 'vuex'
import cloneDeep from 'clone-deep';

export default {
  name: 'CreateBigContainer',
  props: ['onCreated', 'onCancel'],
  data: () => ({
    item: {
      orderCodeOrTrackingNumbers: ''
    },
    warehouse_option: [
        {
            "name": "LAX",
            "value": "LAX"
        },
        {
            "name": "TEXAS",
            "value": "TEXAS"
        },
    ]
  }),
  computed: {
    ...mapGetters({
      roles: 'big_container/all'
    }),
  },
  methods: {
    ...mapActions({
      createItem: 'big_container/create'
    }),
    processAdd() {
      let item = cloneDeep(this.item);
      item.orderCodeOrTrackingNumbers = item.orderCodeOrTrackingNumbers.split('\n').filter(c => c.trim() !== '')
      this.createItem(item).then(() => {
        this.item = {
          orderCodeOrTrackingNumbers: ''
        };
        this.onCreated();
      });
    }
  }
}
</script>
