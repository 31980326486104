<template>
  <v-card tile :elevation="0" class="crud-box list-big_containers">
    <h1 class="mx-4">Quản Lý Kiện Lớn</h1>
    <v-layout wrap class="px-4 pt-4">
      <div class="mb-5" style="overflow: visible; width: 100%;">
        <v-btn color="success" class="mr-2" @click="onClickNew">
          <v-icon>mdi-plus</v-icon>
          New
        </v-btn>
        <!--        <v-btn color="primary" outlined class="mr-2" @click="onClickImport">
                  <v-icon>mdi-database-import</v-icon>
                  Import
                </v-btn>-->
        <v-btn color="primary" outlined class="mr-2" @click="exportItems">
          <v-icon>mdi-database-export</v-icon>
          Export
        </v-btn>
        <v-btn color="error" v-if="pickedItems.length > 0" class="mr-2" @click="onClickDeleteAll">
          <v-icon>mdi-delete</v-icon>
          Delete Items
        </v-btn>
      </div>
      <!---->
      <v-spacer></v-spacer>
      <v-layout wrap class="search-fields-page">
        <v-text-field dense outlined @keyup.enter="doSearch" class="mr-2" label="Search Name"
                      v-model="searchFields.name.value"/>
        <v-text-field dense outlined @keyup.enter="doSearch" class="mr-2" label="Search Code"
                      v-model="searchFields.code.value"/>
        <v-text-field dense outlined @keyup.enter="doSearch" class="mr-2" label="Search TrackingNumber"
                      v-model="searchFields.tracking_number.value"/>
        <v-text-field dense outlined @keyup.enter="doSearch" class="mr-2" label="Search Carrier"
                      v-model="searchFields.carrier.value"/>
        <v-text-field dense outlined @keyup.enter="doSearch" class="mr-2" label="Search warehouse"
                      v-model="searchFields.warehouse_name.value"/>
        <v-text-field dense outlined @keyup.enter="doSearch" class="mr-2" label="Search Status"
                      v-model="searchFields.status.value"/>
        <v-text-field dense outlined @keyup.enter="doSearch" class="mr-2" label="Search RegisterTrackingStatus"
                      v-model="searchFields.register_tracking_status.value"/>
        <v-menu
          v-model="showCreatedAtOrderDatePicker"
          :close-on-content-click="false"
          max-width="290"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field label="Ngày tạo order" class="mr-2" @click:clear="clearCreatedOrderSearch()" clearable dense outlined readonly
                          v-on="on" v-bind="attrs" :value="DateRangeText(createdDates)"
                          @click="showCreatedAtOrderDatePicker = true"></v-text-field>
          </template>
          <v-date-picker v-model="createdDates" range></v-date-picker>
        </v-menu>
      </v-layout>
      <v-btn color="primary" @click="doSearch">
        <v-icon>mdi-magnify</v-icon>
        Search
      </v-btn>
    </v-layout>

    <!--card content-->
    <v-card tile :elevation="1" :disabled="tblLoading" outlined class="block-tbl mx-4">
      <v-data-table :headers="tblHeader" :items="listItems" mobile-breakpoint="0" :server-items-length="tblPagination.total" :loading="tblLoading"
                    :footer-props="tblConfig.footerProps" @dblclick:row="onClickRow" :options.sync="tblOptions"
                    :loading-text="tblConfig.text.loading" v-model="pickedItems" :show-select="tblConfig.showSelect" :expanded.sync="expanded"  :single-expand="singleExpand" show-expand class="elevation-1">
        <template v-slot:no-data>
          {{ tblConfig.text.noItem }}
        </template>

        <template v-slot:item.no="{item}">
          {{ getIndexOfItem(item) }}
        </template>
        <template v-slot:item.total_fee_big_container="{item}">
          <money-format :value="getTotalFeeBigContainer(item)" style="display: inline-block;"
                        :hide-subunits=true :locale="'vi'" :currency-code="'VND'"></money-format>
        </template>
        <template v-slot:item.register_tracking_status="{item}">
          {{ formatTrackingRegisterStatusName(item.register_tracking_status) }}
        </template>
        <template v-slot:item.status="{item}">
          {{ formatStatusName(item.status) }}
        </template>

        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length" class="table_expanded">
            <v-simple-table>
              <template v-slot:default>
                <thead>
                <tr>
                  <th>#</th>
                  <th>Tên đơn hàng</th>
                  <th>Code</th>
                  <th>Cước vận chuyển</th>
                  <th>Trạng thái</th>
                  <th>Người tạo</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(dessert, k) in item.orders" :key="k">
                  <td>{{ (page - 1) * 15 + k + 1 }}</td>
                  <td>{{ dessert.name }}</td>
                  <td>{{ dessert.code }}</td>
                  <td><money-format :value="getTotalFee(dessert)" style="display: inline-block;"
                                    :hide-subunits=true :locale="'vi'" :currency-code="'VND'"></money-format></td>
                  <td v-if="dessert.departed_date && dessert.status == 3"><div>Departed</div></td>
                  <td v-else><div v-html="orderGetStatusText(dessert.status).replace(' - ', '<br>')"></div></td>
                  <td>{{ dessert.customer ? dessert.customer.email : '' }}</td>
                </tr>
                </tbody>
              </template>
            </v-simple-table>
          </td>
        </template>

        <template v-slot:item.actions="props">
          <v-icon small @click="onClickAddFee(props)" class="mr-2" color="teal">mdi-plus</v-icon>
          <v-icon small @click="onClickDetail(props)" class="mr-2" color="primary">mdi-view-list</v-icon>
          <v-icon small @click="onClickEdit(props)" class="mr-2" color="success">mdi-pencil</v-icon>
          <v-icon small @click="onClickDelete(props)" color="error">mdi-delete</v-icon>
        </template>
      </v-data-table>
    </v-card>

    <v-dialog v-model="dialog.create" :width="800">
      <CreateItem :onCreated="onCreated" :onCancel="()=>{dialog.create=false}"></CreateItem>
    </v-dialog>
    <v-dialog v-model="dialog.detail" :width="'60%'">
      <DetailItem :selectedItem="selectedItem" :onClose="()=>{dialog.detail=false}"></DetailItem>
    </v-dialog>
    <v-dialog v-model="dialog.addFee" :width="'60%'">
      <AddFeeItem :selectedItem="selectedItem" :onClose="()=>{dialog.addFee=false}"></AddFeeItem>
    </v-dialog>
    <v-dialog v-model="dialog.update" :width="800">
      <UpdateItem :onUpdated="onUpdated" :selectedItem="selectedItem" :onCancel="()=>{dialog.update=false}"></UpdateItem>
    </v-dialog>
    <v-dialog v-model="dialog.delete" :width="400">
      <DeleteItem :idsToDelete="idsToDelete" :onDeleted="onDeleted" :onCancel="()=>{dialog.delete=false}"></DeleteItem>
    </v-dialog>
    <v-dialog v-model="dialog.import" :width="400">
      <ImportItem :onImported="onImported" :onCancel="()=>{dialog.import=false}"></ImportItem>
    </v-dialog>
  </v-card>
</template>
<script>
import {mapActions, mapGetters} from 'vuex'
import CreateItem from "./Create";
import DetailItem from "./Detail";
import UpdateItem from "./Update";
import AddFeeItem from "./AddFee";
import DeleteItem from "./Delete";
import ImportItem from "./Import";
import indexMixin from './indexMixin';
import MoneyFormat from "vue-money-format";

export default {
  name: 'ManageBigContainers',
  components: {CreateItem, DetailItem, UpdateItem, DeleteItem, ImportItem, MoneyFormat, AddFeeItem},
  mixins: [indexMixin],
  data() {
    return {
      expanded: [],
      createdDates: [],
      singleExpand: false,
      page: 1,
      options: {},
      showCreatedAtOrderDatePicker: false,
      searchFields: {
        name: {
          value: '',
          type: 'like',
        },
        code: {
          value: '',
          type: 'like',
        },
        tracking_number: {
          value: '',
          type: 'like',
        },
        carrier: {
          value: '',
          type: 'like',
        },
        warehouse_name: {
            value: '',
            type: 'like',
        },
        status: {
          value: '',
          type: 'like',
        },
        register_tracking_status: {
          value: '',
          type: 'like',
        },
        created_date_from: {
          value: '',
          type: '=',
          display: false,
          force_hidden: true
        },
        created_date_to: {
          value: '',
          type: '=',
          display: false,
          force_hidden: true
        },
      },
      searchWith: 'orders;orders.orderFees;orders.customer;bigContainerFees',
      searchWithCount: '',
      tblHeader: [
        {
          text: 'No',
          value: 'no',
          sortable: false,
          groupable: false,
          width: '55px',
          align: 'start',
        },

        {
          text: 'Name',
          value: 'name',
        },
        {
          text: 'Code',
          value: 'code',
        },
        {
          text: 'Total Fee',
          value: 'total_fee_big_container',
          sortable: false,
        },
        {
          text: 'Tracking Number',
          value: 'tracking_number',
        },
        {
          text: 'Register Tracking Status',
          value: 'register_tracking_status',
        },
        {
          text: 'Carrier',
          value: 'carrier',
        },
        {
            text: 'Warehouse',
            value: 'warehouse_name',
        },
        {
            text: 'Supplier',
            value: 'supplier',
        },
        {
          text: 'Status',
          value: 'status',
        },
        {
            text: 'Departed date',
            value: 'departed_date',
        },
        {
            text: 'Delivered date',
            value: 'delivered_date',
        },
        {
          text: 'Note',
          value: 'note',
        },
        {
          text: 'Weight',
          value: 'weight',
        },

        {
          text: 'Actions',
          value: 'actions',
          sortable: false,
          groupable: false,
          width: '141px',
          align: 'center',
        }
      ],
    }
  },
  computed: {
    ...mapGetters({
      items: 'big_container/all',
      tblPagination: 'big_container/pagination',
      orderGetStatusText: 'order/getStatusText',
    }),
    DateRangeText() {
      return dateArr => {
        return dateArr.join(' ~ ')
      }
    },
  },
  methods: {
    ...mapActions({
      getListItems: 'big_container/get',
      exportListItems: 'big_container/exportItem',
    }),
    clearCreatedOrderSearch() {
      this.searchFields.created_date_to.value = '';
      this.searchFields.created_date_from.value = '';
    },
    getTotalFee(order) {
        let totalFee = 0;
        order.order_fees.forEach(f => totalFee += parseFloat(f.fee_amount));
        return parseInt(totalFee);

    },
    getTotalFeeBigContainer(data) {
      let totalFee = 0;
      if (!data.big_container_fees) return 0;
      data.big_container_fees.forEach(f => totalFee += parseFloat(f.fee_amount));
      return parseInt(totalFee);
    },
    formatStatusName(status) {
      let res = status;
      for (let key in this.CONSTANTS.BIG_CONTAINER_STATUS) {
        if (this.CONSTANTS.BIG_CONTAINER_STATUS[key] === status) res = key;
      }
      return res;
    },
    formatTrackingRegisterStatusName(status) {
      let res = status;
      for (let key in this.CONSTANTS.TRACKING_NUMBER_REGISTER_STATUS) {
        if (this.CONSTANTS.TRACKING_NUMBER_REGISTER_STATUS[key] === status) res = key;
      }
      return res;
    },
    exportItems() {
        if (this.pickedItems.length == 0) return this.noticeWarning('Item not found');
        let ids = [];
        this.pickedItems.forEach(item => {
            ids.push(item.id)
        });

        this.exportListItems({
            ids: ids,
            successCallback: (res) => {
            },
            errCallback: (res) => {
                console.log(res)
            },
        })
    },
  },
  watch: {
      createdDates: {
        handler(val) {
          if (!Array.isArray(val)) return;
          if (val[0] && !isNaN(Date.parse(val[0]))) this.searchFields.created_date_from.value = val[0];
          if (val[1] && !isNaN(Date.parse(val[1]))) this.searchFields.created_date_to.value = val[1];
        },
        deep: true,
        immediate: true
      },
      options: {
          handler() {
              if (this.page == this.options.page) return
              this.page = this.options.page
              this.getAll()
          },
          deep: true,
      },
      clickSearch: {
          handler() {
              this.page = this.options.page
              this.getAll()
          },
          deep: true,
      },
  }
}
</script>
<style scoped>
  .table_expanded >>> .v-data-table.theme--light {
      background: #ddd;
   }
</style>
